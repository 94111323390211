import React, { useEffect, useState } from "react"
import { ArrowLeft } from "react-feather"
import { useHistory, useParams } from "react-router"
import generateData from "../../assets/data/DataFunction"
export default function Article() {
  const { id } = useParams()
  const history = useHistory()

  const [doa, setDoa] = useState({})
  useEffect(() => {
    console.log(id)
    const data = generateData(id, true)
    console.log(data)
    setDoa(data)
  }, [])
  return (
    <>
      <header className="article-header">
        <ArrowLeft onClick={() => history.goBack()} />
      </header>
      <section className="article-section">
        {!(
          doa && // 👈 null and undefined check
          Object.keys(doa).length === 0 &&
          Object.getPrototypeOf(doa) === Object.prototype
        ) && (
          <>
            <span className="category-display">{doa.category}</span>
            <h1 style={{ marginBottom: 15, wordWrap: "break-word" }}>
              {doa.sTitle}
            </h1>
            <div
              className="arabic-container"
              dangerouslySetInnerHTML={{ __html: doa.sArabic }}
            ></div>
            <div
              className="translation-container"
              dangerouslySetInnerHTML={{ __html: doa.sTrans }}
            ></div>
            <div
              className="translation-container"
              dangerouslySetInnerHTML={{ __html: doa.sDesc }}
            ></div>
          </>
        )}
      </section>
    </>
  )
}
