import { shared, mydata } from "./Data"

const getMappedData = () => {
  const mapped = mydata.aAllPrayers.map((el, i) => {
    const cat = mydata.aAllCat.filter((elC, iC) => elC.idCat === el.idCat)
    return { ...el, category: cat[0].sTitle }
  })

  return mapped
}
const generateData = (search = null, usingId = false) => {
  if (usingId) {
    const data = getMappedData()
    const toReturn = data.filter((el, i) => el.idPrayer == search)
    console.log(search)
    return toReturn[0]
  } else {
    if (search == null) {
      return getMappedData()
    } else {
      const array = getMappedData()
      const toReturn = array.filter((el, i) => {
        const s = el.sTitle
          .toLowerCase()
          .search(search.replace(/doa/gi, "").toLowerCase())
        return s > 0
      })
      console.log(toReturn)
      return toReturn
    }
  }
}

export default generateData
