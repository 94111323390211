import logo from './logo.svg';
import './App.css';
import WebRouter from './utility/router';

function App() {
  return (
    <WebRouter/>
  );
}

export default App;
