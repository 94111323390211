import React from "react"
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom"
import Article from "../pages/article"
import Home from "../pages/home"
export default function WebRouter() {
  return (
    <Router basename={"/"}>
      <Switch>
        <Route path="/article/:id">
          <Article />
        </Route>
        <Route exact path="/">
          <Home />
        </Route>
      </Switch>
    </Router>
  )
}
