import React, { useEffect, useState } from "react"
import { Search } from "react-feather"
import HomeDoaList from "../../components/HomeDoaList"
import { useHistory, useLocation } from "react-router"
import queryString from "query-string"
import generateData from "../../assets/data/DataFunction"
export default function Home(props) {
  const query = useLocation().search

  const isSearching = () => {
    const obj = queryString.parse(query)
    if (obj["search"] !== undefined || search != "") {
      return true
    } else {
      return false
    }
  }

  const history = useHistory()
  const clickSearch = () => {
    if (search.length > 0) {
      setContainer(container + " --searching")
      setTitle(title + " --hidden")
      doGenerateData()
      history.push(`/?search=${search}`)
    } else {
      window.location.reload()
    }
  }

  const doGenerateData = () => {
    let newData = []

    if (isSearching() || search != "") {
      const data = generateData(search)
      setDoa(data)
      setContainer(container + " --searching")
      setTitle(title + " --hidden")
    } else {
      const data = generateData()
      for (let index = 0; index < 7; index++) {
        const random = Math.floor(Math.random() * data.length)
        newData = [...newData, data[random]]
      }

      setDoa(newData)
    }
  }

  const [doa, setDoa] = useState([])
  const [search, setSearch] = useState("")

  const [container, setContainer] = useState("home-container")
  const [title, setTitle] = useState("")
  useEffect(() => {
    console.log(search)
    doGenerateData()
  }, [search])

  useEffect(() => {
    if (isSearching()) {
      const obj = queryString.parse(query)
      setSearch(obj.search)

      setContainer(container + " --searching")
      setTitle(title + " --hidden")
    }
  }, [])

  return (
    <>
      <div className={`container ${container}`}>
        <div className="search-container">
          <h1 className={title}>Cari Do'a</h1>
          <input
            type={"search"}
            value={search}
            onChange={(event) => setSearch(event.target.value)}
            className="form-control"
            placeholder="doa makan..."
          ></input>
          <button
            className="btn btn-primary"
            style={{ marginTop: 10 }}
            onClick={() => clickSearch()}
          >
            Cari <Search size={20} />
          </button>
        </div>
      </div>
      <div className="container">
        <div className="row">
          {doa.map((el, i) => (
            <div className="col-sm-12 col-lg-6">
              <HomeDoaList
                title={el.sTitle}
                view={el.view}
                slug={el.idPrayer}
                preview={el.sArabic != "" ? el.sArabic : el.sDesc}
                isArabic={el.sArabic != "" ? true : false}
              />
            </div>
          ))}
        </div>
      </div>
    </>
  )
}
