import React from "react"
import { Eye } from "react-feather"

export default function HomeDoaList({
  title,
  view,
  slug,
  preview,
  isArabic,
  ...props
}) {
  return (
    <>
      <a href={`/article/${slug}`}>
        <div
          className="container doa-list"
          style={{ borderRadius: 10, backgroundColor: "#D7E9F4" }}
        >
          <p>
            <strong>{title}</strong>
          </p>
          {/* <div
            className={`preview ${isArabic ? "--right" : ""}`}
            dangerouslySetInnerHTML={{ __html: preview }}
          ></div> */}
          <p>{/* <Eye /> {view} */}</p>
        </div>
      </a>
      {/* <div className="overlay"></div> */}
    </>
  )
}
